* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'All Round Gothic';
  src: url('./fonts/all-round-gothic-medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body, html {
  height: 100%;
  font-family: Arial, sans-serif;
  background-color: #2C1548;
  color: #fff;
  overflow: hidden;
}

.game-container {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.overlay {
  font-family: 'All Round Gothic', sans-serif;
  position: absolute;
  padding: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 20px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: left;
  cursor: grab;
}

.start-button {
  padding: 5px 10px;
  font-size: 15px;
  cursor: pointer;
  background: #ffffff;
  color: #282c34;
  border: none;
  border-radius: 3px;
}

.grid-container {
  display: grid;
  background-color: #2C1548;
  gap: 0; /* Ensure no gaps between cells */
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-image {
  width: 100%;
  height: 100%;
}

.meat-image {
  width: 100%; /* Meat image size */
  height: 100%;
}

.bone-image {
  width: 77%; /* Bone image size */
  height: 77%;
}

.submit-score {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.submit-score input {
  padding: 5px;
  font-size: 14px;
  margin-bottom: 5px;
}

.submit-score button {
  padding: 5px 10px;
  font-size: 14px;
}

.leaderboard {
  margin-top: 20px;
  background: rgba(255, 255, 255, 0.9);
  color: #000;
  padding: 10px;
  border-radius: 5px;
  max-height: 140px;
  overflow-y: auto;
}

.leaderboard h3 {
  margin-bottom: 10px;
}

.leaderboard ol {
  padding-left: 20px;
}

.leaderboard li {
  margin-bottom: 5px;
}